/*
 * Copyright (C) 2016 Bilibili. All Rights Reserved.
 *
 * @author zheng qian <xqq@xqq.im>
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

export const defaultConfig = {
    enableWorker: false,
    enableStashBuffer: true,
    stashInitialSize: undefined,

    isLive: false,

    lazyLoad: true,
    lazyLoadMaxDuration: 3 * 60,
    lazyLoadRecoverDuration: 30,
    deferLoadAfterSourceOpen: true,

    // autoCleanupSourceBuffer: default as false, leave unspecified
    autoCleanupMaxBackwardDuration: 3 * 60,
    autoCleanupMinBackwardDuration: 2 * 60,

    statisticsInfoReportInterval: 600,

    fixAudioTimestampGap: true,

    accurateSeek: false,
    seekType: 'range',  // [range, param, custom]
    seekParamStart: 'bstart',
    seekParamEnd: 'bend',
    rangeLoadZeroStart: false,
    customSeekHandler: undefined,
    reuseRedirectedURL: false,
    // referrerPolicy: leave as unspecified

    headers: undefined,
    customLoader: undefined,

    //---------------------------------自定义新增属性  开始----------------------------------
    videoStateMonitorInterval: 1000,  // 多长时间（毫秒）检查一次视频状态（延时、停滞）
    enableDurationMonitor: false, // true表示监测当前直播流延时，当发现延时过大时，主动追赶
    enableVideoFrozenMonitor: false, // 监测视频解码是否停滞（画面卡停），当因为某些原因导致无法解码时，将上报VIDEO_FROZEN事件，收到后建议重拉流

    audioGainLevel: 2, 
    latencyDetectInterval: 60000,   // 毫秒，多长时间统计一次延迟追赶次数
    latencyDetectThreshold: 2,      // 次数，每次统计周期内，超过这个次数认为延迟追赶太频繁了
    latencyDetectAdjustStep: 0.1,   // 每次发生追赶，都上调此数值（秒）。当达到不发生追赶的条件时，下调此数值。
    lowLatencyThreshold: 1.5,   // 延迟追赶判断阈值下限
    highLatencyThreshold: 10,   // 延迟追赶判断阈值上限
    decreaseDurationStep: 0.4,  // 每次追赶至缓冲区末尾之前的多少秒
    frozenTimesThreshold: 5,  // 解码停滞次数达到此阈值，上报VIDEO_FROZEN事件。注意如果设置过小的阈值，当推流端关闭摄像头后可能会频繁触发VIDEO_FROZEN事件
    

    // webrtc合流未能给cdn推送正确的视频分辨率信息，导致从MetaData/AVCDecoderConfigurationRecord中无法拿到正确的视频分辨率
    // 在内核低于Chromium 70的浏览器（如360浏览器、搜狗浏览器、PC微信内嵌浏览器等）中
    // 如果传递不对的视频分辨率，将会导致画面放大显示异常，因此，强行设置一个最大的视频宽高信息，来避免此问题
    // 如果是Safari浏览器，enableConstVideoViewSize建议设置为false
    enableConstVideoViewSize: false,
    constVideoViewWidth: 1920,  
    constVideoViewHeight: 1080,

    //---------------------------------自定义新增属性  结束----------------------------------
};

export function createDefaultConfig() {
    return Object.assign({}, defaultConfig);
}